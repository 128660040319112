import ResizeObserver from 'resize-observer-polyfill';

let instance = null;

class ResizeObserverSingleton {
  constructor() {
    if (instance) {
      return instance;
    }
    // eslint-disable-next-line consistent-this
    instance = this;
    this.resizeHandlers = new Map();
    const resizeHandler = entries => {
      window.requestAnimationFrame(() => {
        for (const entry of entries) {
          const handler = this.resizeHandlers.get(entry.target);
          if (handler) {
            handler(entry);
          }
        }
      });
    };
    this.resizeObserver = new ResizeObserver(resizeHandler);
  }

  // Options are not supported when the polyfill is used
  addObserver(target, handler, options) {
    this.resizeHandlers.set(target, handler);
    this.resizeObserver.observe(target, options);
  }

  removeObserver(target) {
    this.resizeHandlers.delete(target);
    this.resizeObserver.unobserve(target);
  }
}

export default ResizeObserverSingleton;

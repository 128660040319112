import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {removeCorsParam} from 'shared/ui/helpers/loadImage';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

export const tombstoneImage = `data:image/svg+xml,${encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 54">
    <defs>
      <path id="WhiteMonogramA" d="M0 0h99.823v53.686H0z">
      </path>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="WhiteMonogramB" fill="#fff">
        <use xlink:href="#WhiteMonogramA"></use>
      </mask>
      <path
        fill="#FFF" d="M63.289 41.304c-1.48 1.78-3.18 3.29-4.736 4.488a.475.475 0 0 0-.131-.055 28.477 28.477 0 0 0 4.867 2.456v-6.89zm-15.631-5.572c-6.9-8.529-9.556-15.604-9.64-21.071C37.79.18 54.703-3.672 63.288 3.544v25.75c-1.28 2.26-2.808 4.618-4.572 7.042 0 0-1.837 2.578-5.069 5.815C21.035 73.703.629 33.588 0 4.753V2.035c.004-.162.005-.326.01-.486h12.026c1.215 14.532 3.826 38.972 20.205 41.657 4.72.775 10.128-1.005 16.102-6.639 2.821-2.414 5.035-6.017 5.035-6.017C72.54 2.943 34.029 4.298 52.35 29.2c-.845 3.09-4.692 6.532-4.692 6.532zm15.63 12.46c18.264 7.063 34.93-10.393 36.535-32.219l-8.583-.385a8.31 8.31 0 0 0-.238 1.171c-2.197 16.933-12.005 31.242-26.798 23.364-.293.406-.6.8-.915 1.18v6.89zm0-44.648c2.857 2.402 4.793 6.026 4.87 10.892.062 3.964-1.603 9.094-4.87 14.859V3.544z" mask="url(#WhiteMonogramB)">
      </path>
    </g>
  </svg>`
)}`;

export const failedImage = `data:image/svg+xml,${encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
      <g transform="translate(-1 -1) scale(1.2 1.2)">
          <defs>
              <path id="image16" d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM6 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm-3 8l2-4 2 2 3-4 3 6H3z"/>
          </defs>
          <use fill="#FFF" fill-rule="nonzero" xlink:href="#image16"/>
      </g>
    </svg>
  `
)}`;

/**
 * Using this component while loading an image helps to provide a better UX
 * since it creates a placeholder image while waiting for the real image to
 * be loaded and shows a fallback image in case a failure occurs.
 */
class TombStonedImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      loaded: false,
      src: props.src
    };
    this.imgRef = undefined;
    this.tombstoneRef = undefined;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.src !== prevState.src) {
      return {
        failed: false,
        loaded: false,
        src: nextProps.src
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      if (this.imgRef) {
        this.imgRef.setAttribute('data-loading', true);
        this.imgRef.removeAttribute('data-failed');
      }
    }
  }

  setTombstoneRef = ref => {
    if (!ref) {
      return;
    }
    this.tombstoneRef = ref;
  };

  setImageRef = ref => {
    if (!ref) {
      return;
    }
    this.imgRef = ref;
  };

  render() {
    const {failed, loaded} = this.state;
    const {
      tombstone = tombstoneImage,
      fallback = failedImage,
      handleLoaded = _ => _,
      handleFailed = _ => _,
      ...props
    } = this.props;

    const {src} = this.state;

    const fallbackIsReactElement = fallback && React.isValidElement(fallback);
    if (failed && fallbackIsReactElement) {
      const Kind = fallback.type;
      return <Kind {...fallback.props} className={clsx(styles.tombstoned, fallback.props?.className)} data-failed />;
    }

    return (
      <>
        {!loaded || !src ? (
          <img
            ref={this.setTombstoneRef}
            {...props}
            className={clsx(styles.tombstoned, props.className)}
            src={tombstone}
            data-loading
            onLoad={() => {
              handleLoaded(this.tombstoneRef);
            }}
          />
        ) : null}
        {src ? (
          <img
            {...props}
            className={clsx(styles.tombstoned, props.className)}
            key={src}
            style={{display: loaded ? 'block' : 'none'}}
            src={removeCorsParam(src)}
            onLoad={() => {
              this.setState({loaded: true});
              this.imgRef.removeAttribute('data-loading');
              handleLoaded(this.imgRef);
            }}
            onError={error => {
              if (fallbackIsReactElement) {
                this.setState({failed: true});
                return;
              }

              this.imgRef.src = fallback;
              this.imgRef.removeAttribute('data-loading');
              this.imgRef.setAttribute('data-failed', true);
              handleFailed(this.imgRef, error);
            }}
            ref={this.setImageRef}
            crossOrigin={src && src.startsWith && src.startsWith('http') ? 'anonymous' : undefined}
            data-loading
          />
        ) : null}
      </>
    );
  }
}

TombStonedImage[symbols.Image.TombStoned] = true;

TombStonedImage.displayName = 'Image.TombStoned';

TombStonedImage.propTypes = {
  /** The image to finally show */
  src: PropTypes.oneOfType([
    // Promise
    PropTypes.shape({
      then: PropTypes.func,
      catch: PropTypes.func
    }),
    // URL
    PropTypes.string
  ]),
  /**
   * Override the default tombstone image with another base64 or URI encoded image
   * This image is shown while waiting for the desired image to be loaded
   */
  tombstone: PropTypes.string,
  /**
   * Override the default fallback image with another base64 or URI encoded image
   * This image is shown when load failed
   */
  fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Callback when the desired image has been loaded */
  handleLoaded: PropTypes.func,
  /** Callback when the image could not be loaded */
  handleFailed: PropTypes.func
};

export default TombStonedImage;

import React from 'react';
import Text from 'shared/ui/atoms/text/body';
import {setSymbol} from 'shared/ui/atoms/icon/base';

const Affix = ({dataPlacement, left, ...props}) => <Text {...props} secondary data-placement={dataPlacement} />;

setSymbol(Affix);

Affix.displayName = 'Affix';

export default Affix;

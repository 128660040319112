import {STORE_LOCATION, KEY_STORE_PREFIX} from './constants';
const {LOCAL, SESSION, UNKNOWN} = STORE_LOCATION;

export const getKeyStoreName = key => {
  if (!key) {
    return null;
  }
  return key.startsWith(KEY_STORE_PREFIX) ? key : `${KEY_STORE_PREFIX}-${key}`;
};

export const getStorageByLocation = location =>
  location === LOCAL ? localStorage : location === SESSION ? sessionStorage : null;

export const getLocationByStorage = storage =>
  storage === localStorage ? LOCAL : storage === sessionStorage ? SESSION : UNKNOWN;

export const serializeObject = _ => JSON.stringify(_);

export const deserializeObject = _ => JSON.parse(_);

export const defaultIsEmptyState =  state => state === null || state === undefined || state === '' || (state instanceof Object && !Object.keys(state).length);

export const canSupportStorage = (storage) => {
  const testKey = 'evergreen-testing-support';
  let isSupported = false;
  try {
    storage.setItem(testKey, testKey);
    isSupported = storage.getItem(testKey) === testKey;
    storage.removeItem(testKey);
  } catch(e) {
    isSupported = false;
  }

  return isSupported;
}

export default function getNameInitials({name, firstName, lastName}) {
  let lastWord;
  // eslint-disable-next-line prefer-const
  let [firstWord, ...rest] = name ? name.split(' ').filter(Boolean) : [];

  if (rest && rest.length) {
    lastWord = rest[rest.length - 1];
  }

  if (firstName && firstName.trim()) {
    firstWord = firstName.trim();
  }

  if (lastName && lastName.trim()) {
    lastWord = lastName.trim();
  }

  if (!firstWord) {
    throw new Error('Nothing passed in getNameInitials');
  }

  if (!lastWord) {
    return `${firstWord.charAt(0)}${firstWord.charAt(1) || firstWord.charAt(0)}`.toUpperCase();
  }

  return `${firstWord.charAt(0)}${lastWord.charAt(0)}`.toUpperCase();
}

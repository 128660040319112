import React, {createContext, useContext, useState, useCallback, useEffect} from 'react';
import detectDevice, {Device} from './detectDevice';

export const DeviceStateContext = createContext();
export const DeviceUpdaterContext = createContext();

const DeviceProvider = ({children, mobileMediaQueries}) => {
  const [device, setDevice] = useState(() => detectDevice(mobileMediaQueries));

  useEffect(() => setDevice(detectDevice(mobileMediaQueries)), [mobileMediaQueries]);

  return (
    <DeviceStateContext.Provider value={device}>
      <DeviceUpdaterContext.Provider value={setDevice}>{children}</DeviceUpdaterContext.Provider>
    </DeviceStateContext.Provider>
  );
};

export const useDeviceState = () => {
  return useContext(DeviceStateContext);
};

export const useDeviceUpdater = mobileMediaQueries => {
  const setDevice = useContext(DeviceUpdaterContext);
  return useCallback(() => setDevice(detectDevice(mobileMediaQueries)), [setDevice, mobileMediaQueries]);
};

const useDevice = mobileMediaQueries => {
  return [useDeviceState(), useDeviceUpdater(mobileMediaQueries)];
};

export {DeviceProvider, useDevice, Device};

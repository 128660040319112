import MainBody from './mainBody';
import Body from './body';
import Secondary from './secondary';
import Subheading from './subheading';

export default {
  MainBody,
  Body,
  Secondary,
  Subheading
};

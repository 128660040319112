import PropTypes from 'prop-types';
import IllustratedInput, {withClearable} from 'shared/ui/atoms/input/text/illustrated';

const Search = withClearable(IllustratedInput, {type: 'search'});

Search.displayName = 'Search';

Search.propTypes = {
  ...IllustratedInput.propTypes,
  ...withClearable.propTypes,
  /** All the texts might be used inside the component. */
  texts: PropTypes.object
};

export default Search;

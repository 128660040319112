import React from 'react';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import colorPick from 'shared/ui/helpers/colorpick';

const DEFAULT_TEXT_COLOR = 'grey700';
const BACKGROUNDS = [
  'green100',
  'orange200',
  'grey400',
  'grey300',
  'orange100',
  'red100',
  'petrol200',
  'purple50',
  'blue100'
];

const colorPicker = palette => colorPick().strictPalette(palette);

const getBackgroundColor = ({text, seed: seedProp, background, palette}) => {
  const seed = seedProp || text;
  return colorPicker(palette).pick(background) || colorPicker(palette).pickRandom(seed);
};

class Canvas {
  constructor(size) {
    this.initializeCanvas(size);
  }

  initializeCanvas(size) {
    this.canvas = document.createElement('canvas');
    this.canvas.width = size;
    this.canvas.height = size;
    this.ctx = this.canvas.getContext('2d');
    this.initialCtxState = {
      globalCompositeOperation: this.ctx.globalCompositeOperation
    };
  }

  resetContext() {
    Object.keys(this.initialCtxState).forEach(prop => {
      this.ctx[prop] = this.initialCtxState[prop];
    });
  }

  setBackground(color) {
    const {width, height} = this.canvas;
    this.ctx.globalCompositeOperation = 'destination-over';
    this.ctx.fillStyle = color;
    this.ctx.fillRect(0, 0, width, height);
    this.resetContext();
  }

  calculateFontSize(width, text) {
    const lettersAfter2count = Math.max(text.length - 2, 0); // The amount of letters exceeding 2, minimum is 0
    const widthAfter30pixels = Math.max(width - 30, 0); // The amount of pixels exceeding 30, minimum is 0

    const MINIMUM_FONT_SIZE = 14 - lettersAfter2count * 4; // The minimum font-size

    return Math.min(MINIMUM_FONT_SIZE + widthAfter30pixels * 0.4, width / (text.length || 1));
  }

  writeText(text, color) {
    const {width, height} = this.canvas;
    const textColor = color;
    const fontSize = this.calculateFontSize(width, text);

    this.ctx.globalCompositeOperation = 'source-over';
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillStyle = textColor;
    this.ctx.font = `normal normal 300 ${fontSize}px "-apple-system", BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif`;
    this.ctx.fillText(text, width / 2, height / 2);
    this.resetContext();
  }

  toDataURL(mime = 'image/png') {
    return this.canvas.toDataURL(mime);
  }
}

export const getTextImage = ({size = 200, text, color = DEFAULT_TEXT_COLOR, seed, background, customPalette}) => {
  const canvas = new Canvas(size);

  const backgroundColor = getBackgroundColor({text, seed, background, palette: customPalette || BACKGROUNDS});

  canvas.setBackground(backgroundColor);
  canvas.writeText(text, colorPick().pick(color));

  return canvas.toDataURL();
};

class TextImage extends React.Component {
  render() {
    const {size, color, handleLoaded, ...props} = this.props;
    const {text, seed, background, handleFailed, fallback, customPalette, ...imageProps} = props;

    const textImage = getTextImage({size, text, color, seed, background, customPalette});

    return <img {...imageProps} src={textImage} alt={props.alt || props.text} onLoad={props.onLoad || handleLoaded} />;
  }
}

TextImage.defaultProps = {
  size: 200,
  color: DEFAULT_TEXT_COLOR,
  handleLoaded: () => {}
};

TextImage[symbols.Image.Text] = true;

TextImage.propTypes = {
  /**
   * Seed key to define the background color in a deterministic manner.
   * The same seed always produces the same background color.
   * If no seed passed then the `text` is used as seed.
   */
  seed: PropTypes.string,
  /** The text to draw inside the image */
  text: PropTypes.string.isRequired,
  /** The text color */
  color: PropTypes.string,
  /** The background color. Value must be one of the allowed backgrounds */
  background: PropTypes.string,
  /** The size of the image to create */
  size: PropTypes.number
};

TextImage.constants = {
  BACKGROUNDS
};

export default TextImage;

import {$Symbol} from 'shared/ui/symbols';

export const Size = Object.freeze({
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  unspecified: 'unspecified'
});

export const containerBreakpointsBuilderSymbol = $Symbol('ContainerBreakpoints');

export const containerBreakpointsBuilder = () => {
  const breakpoints = new Map();

  let defaultBreakpoint;

  const builder = {
    addMinWidthBreakpoint: (breakpoint, width) => {
      breakpoints.set(breakpoint, containerWidth => containerWidth >= width);
      return builder;
    },
    addMaxWidthBreakpoint: (breakpoint, width) => {
      breakpoints.set(breakpoint, containerWidth => containerWidth <= width);
      return builder;
    },
    addInBetweenBreakpoint: (breakpoint, minWidth, maxWidth) => {
      breakpoints.set(breakpoint, containerWidth => containerWidth >= minWidth && containerWidth <= maxWidth);
      return builder;
    },
    setDefaultBreakpoint: breakpoint => {
      defaultBreakpoint = breakpoint;
      return builder;
    },
    getBreakpoints: () => {
      if (!defaultBreakpoint) {
        throw new Error(
          'The defaultBreakpoint should be defined at `containerBreakpointsBuilder` with `setDefaultBreakpoint` method.'
        );
      }

      breakpoints.set(defaultBreakpoint, () => true);
      return breakpoints;
    }
  };

  builder[containerBreakpointsBuilderSymbol] = true;

  return builder;
};

import React, {createContext, useLayoutEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import {ResizeObserverSingleton} from 'shared/helpers/observer';
import {containerBreakpointsBuilder, containerBreakpointsBuilderSymbol, Size} from './breakpointsBuilder';

const ResizeObserverContext = createContext();

// It is recommended to be used once per app for maximum performance, e.g. wrap your whole app with it.
export const ResizeObserverProvider = ({children}) => {
  const resizeObserver = useRef(new ResizeObserverSingleton());

  return <ResizeObserverContext.Provider value={resizeObserver.current}>{children}</ResizeObserverContext.Provider>;
};

const DEFAULT_CONTAINER_BREAKPOINTS = containerBreakpointsBuilder()
  .setDefaultBreakpoint(Size.unspecified)
  .addMaxWidthBreakpoint(Size.small, 767);

export const useContainerBreakpoint = (ref, containerBreakpoints = DEFAULT_CONTAINER_BREAKPOINTS) => {
  if (!containerBreakpoints[containerBreakpointsBuilderSymbol]) {
    throw new Error(
      'The breakpoints of `useContainerBreakpoint` should be created with `containerBreakpointsBuilder`.'
    );
  }

  const breakpoints = containerBreakpoints.getBreakpoints();

  const resizeObserver = React.useContext(ResizeObserverContext);
  if (!resizeObserver) {
    throw new Error('useContainerBreakpoint should be wrapped inside a ResizeObserverProvider');
  }

  const [breakpoint, setBreakpoint] = useState();
  const previousBreakpoint = useRef();

  useLayoutEffect(() => {
    const handler = entry => {
      // contentRect is used instead of borderBoxSize/contentBoxSize because it has better support
      // and it is the only one the polyfill implements
      const containerWidth = entry.contentRect.width;

      for (const [containerBreakpoint, query] of breakpoints) {
        if (query(containerWidth)) {
          if (previousBreakpoint.current !== containerBreakpoint) {
            setBreakpoint(containerBreakpoint);
            previousBreakpoint.current = containerBreakpoint;
          }
          break;
        }
      }
    };

    if (ref?.current) {
      resizeObserver.addObserver(ref.current, debounce(handler, 60));
    }
    return () => {
      if (ref?.current) {
        resizeObserver.removeObserver(ref.current);
      }
    };
  }, []);

  return breakpoint;
};

import React from 'react';
import clsx from "clsx";
import IllustratedInput from 'shared/ui/atoms/input/text/illustrated';
import SvgSprite from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import pencilSvg from 'assets/svg/pencil8.svg?sprite';
import {setSymbol} from 'shared/ui/atoms/icon/base';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

const PencilIcon = props => <SvgSprite data-pencil svg={pencilSvg} fill transparent {...props} />;
setSymbol(PencilIcon);

const InlineInput = ({pencil, children, ...props}) => {
  const hasPencil = pencil && !props.error && !props.success;

  return (
    (<IllustratedInput
      {...props}
      className={clsx({
        [styles.disabled]: props.disabled,
        [styles.input]: true
      }, props.className)}>
      {children}
      {hasPencil ? <PencilIcon /> : null}
    </IllustratedInput>)
  );
};

const MemoInlineInput = withMemo(InlineInput);

MemoInlineInput[symbols.Input.Inline] = true;

MemoInlineInput.displayName = 'Input.Inline';

export default MemoInlineInput;

import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = props => (
  <BaseSvg {...props}>
    <Graph fill {...props}>
      <path
        d="M11.953 10.635a6.444 6.444 0 0 0 1.318-3.953C13.27 3.012 10.353 0 6.682 0 3.012 0 0 3.012 0 6.682c0 3.67 3.012 6.683 6.682 6.683 1.506 0 2.918-.47 3.953-1.318l2.824 2.824c.188.188.47.282.659.282.188 0 .47-.094.658-.282a.91.91 0 0 0 0-1.318l-2.823-2.918zm-5.27.753c-2.636 0-4.8-2.07-4.8-4.706 0-2.635 2.164-4.8 4.8-4.8 2.635 0 4.8 2.165 4.8 4.8 0 2.636-2.165 4.706-4.8 4.706z"
      />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.MagnifyingGlass';

setSymbol(Icon);

export default Icon;

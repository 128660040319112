import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import propsFilter from 'shared/ui/helpers/propsFilter';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import symbols from 'shared/ui/symbols';

import colors from '../../../css/functions/color.scss';
import styles from './styles.scss'; // eslint-disable-line

const renderChildren = children => {
  const updatedChildren = [];

  React.Children.forEach(children, (child, index) => {
    if (!React.isValidElement(child)) {
      updatedChildren.push(child);
      return;
    }

    const key = child.key || index;

    if (child.type[symbols.Icon]) {
      updatedChildren.push(React.cloneElement(child, {size: child.props.size || 12, key}));
    } else {
      updatedChildren.push(React.cloneElement(child, {key}));
    }
  });
  return updatedChildren;
};

const PillLabel = ({
  success,
  caution,
  danger,
  neutral,
  informational,
  informationalLight,
  informationalDark,
  promotionalBlue,
  ai,
  beta,
  backgroundColor,
  color,
  inverted,
  children,
  style,
  as: ElementType,
  ...props
}) => {
  const filteredProps = propsFilter(props).dataAttributes().styles().getFiltered();
  const hasCustomColor = backgroundColor || color;
  const _backgroundColor = colors[backgroundColor] || backgroundColor;
  const _color = colors[color] || color;

  const styleNames = mapPropsToStyleNames([
    {
      'pill-label': true,
      inverted
    },
    {
      neutral,
      success,
      caution,
      danger,
      informational,
      beta,
      'informational-light': informationalLight,
      'informational-dark': informationalDark,
      'promotional-blue': promotionalBlue,
      ai,
      default: 'default'
    }
  ]);

  return (
    <ElementType
      data-role="pill-label"
      {...filteredProps}
      style={hasCustomColor ? {background: _backgroundColor, color: _color, ...(style || {})} : style}
      className={clsx(
        styleNames
          .split(' ')
          .map(name => styles[name])
          .join(' '),
        filteredProps.className
      )}
    >
      {renderChildren(children)}
    </ElementType>
  );
};

PillLabel.defaultProps = {
  as: 'div'
};

PillLabel.propTypes = {
  /** The value will be rendered as content. */
  children: PropTypes.node,
  /** Morphs container into another Component or element. Defaults to `div` */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /** Controls whether the background-color and font-color should be inverted. */
  inverted: PropTypes.bool
};

PillLabel.displayName = 'PillLabel';

export default PillLabel;

import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import propsFilter from 'shared/ui/helpers/propsFilter';
import SVGSprite from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import ActionableIcon from 'shared/ui/atoms/icon/actionable';
import RemoveSvg from 'assets/svg/remove8.svg?sprite';
import {withTranslations, getEvergreenTranslations} from 'shared/ui/providers/translations';
import styles from './styles.scss';

const RemoveIcon = props => <SVGSprite svg={RemoveSvg} size="8" fill transparent {...props} />;

const ActionableWrapper = props => <ActionableIcon {...props} />;

class Pill extends React.PureComponent {
  handleDismiss = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onDismiss(event);
  };

  handlePillClick = event => {
    if (this.props.disabled) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.props.onClick(event);
  };

  renderChildren = () => {
    const {children} = this.props;
    const updatedChildren = [];

    React.Children.forEach(children, (child, index) => {
      if (!React.isValidElement(child)) {
        updatedChildren.push(child);
        return;
      }

      const key = child.key || index;

      if (child.type[symbols.Icon]) {
        updatedChildren.push(React.cloneElement(child, {size: child.props.size || 12, transparent: true, key}));
      } else {
        updatedChildren.push(React.cloneElement(child, {key}));
      }
    });
    return updatedChildren;
  };

  render() {
    const {
      children,
      selected: _selected,
      onClick,
      onDismiss,
      clickActionLabel,
      dismissActionLabel,
      as: ElementKind,
      success,
      danger,
      warning,
      informational,
      generic,
      disabled,
      ...props
    } = this.props;

    const _texts = {dismiss: dismissActionLabel};

    const {t} = getEvergreenTranslations(this.props);
    const texts = t('pill', _texts);

    const isFlavored = !![success, danger, warning, informational, generic].find(f => f === true);
    const clickable = typeof onClick === 'function';
    const selected = _selected && !isFlavored;
    const dismissable = typeof onDismiss === 'function';

    const filteredProps = propsFilter(props).ariaAttributes().dataAttributes().styles().getFiltered();

    const Wrapper = clickable
      ? wrapperProps => (
          <ActionableWrapper
            onClick={this.handlePillClick}
            handleSpacePressed={this.handlePillClick}
            aria-label={clickActionLabel}
            aria-disabled={disabled}
            {...wrapperProps}
            as={ElementKind}
          />
        )
      : ElementKind;

    return (
      (<Wrapper {...filteredProps} className={clsx([
        {[styles.pill]: true, [styles.selected]: selected, [styles.clickable]: clickable},
        {[styles.success]: success, [styles.danger]: danger, [styles.warning]: warning, [styles.informational]: informational, [styles.generic]: generic},
        {[styles.disabled]: disabled}
      ], filteredProps.className)} data-role="pill">
        <span>{this.renderChildren()}</span>
        {dismissable ? (
          <ActionableWrapper
            handleSpacePressed={this.handleDismiss}
            aria-disabled={disabled}
            onClick={this.handleDismiss}
            className={styles.dismiss}
            data-ui="dismiss"
          >
            <RemoveIcon />
            <span className={styles['hidden-button-label']}>
              {texts.dismiss} {children}
            </span>
          </ActionableWrapper>
        ) : null}
      </Wrapper>)
    );
  }
}

Pill.defaultProps = {
  as: 'div'
};

Pill.propTypes = {
  /** The value will be rendered as content. */
  children: PropTypes.node,
  /** Controls whether it has selected styling. */
  selected: PropTypes.bool,
  /* Renders a disabled pill. User is unable to click it or dismiss it. */
  disabled: PropTypes.bool,
  /** The handler when click the whole pill. */
  onClick: PropTypes.func,
  /** The value will be rendered as aria-label for click action. */
  clickActionLabel: PropTypes.string,
  /** The handler when click dismiss-icon. */
  onDismiss: PropTypes.func,
  /** The value will be rendered as aria-label for dismiss action. */
  dismissActionLabel: PropTypes.string,
  /** Morphs container into another Component or element. Defaults to `div` */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

Pill.displayName = 'Pill';

export default withTranslations(Pill);

import React, {useEffect} from 'react';
import debounce from 'lodash/debounce';
import {DeviceProvider, useDevice} from '../index';

const Responsive = ({children, mobileMediaQueries}) => {
  const [, updateDevice] = useDevice(mobileMediaQueries);

  useEffect(() => {
    const debounceUpdateDevice = debounce(updateDevice, 60);

    window.addEventListener('resize', debounceUpdateDevice);
    window.addEventListener('orientationchange', debounceUpdateDevice);

    return () => {
      if (debounceUpdateDevice.cancel) {
        debounceUpdateDevice.cancel();
      }
      window.removeEventListener('resize', debounceUpdateDevice);
      window.removeEventListener('orientationchange', debounceUpdateDevice);
    };
  }, [updateDevice]);

  return children;
};

export default props => {
  const {mobileMediaQueries} = props;
  return (
    <DeviceProvider mobileMediaQueries={mobileMediaQueries}>
      <Responsive {...props} />
    </DeviceProvider>
  );
};

import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

// eslint-disable-next-line no-unused-vars
const Icon = ({className, graphClassName, transform, right, ...props}) => (
  <BaseSvg {...props} className={className}>
    <Graph fill {...props} className={graphClassName} transform={transform}>
      <polygon points="8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6 " />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.CaretDown';

setSymbol(Icon);

export default Icon;

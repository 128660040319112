import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = props => (
  <BaseSvg {...props}>
    <Graph fill {...props}>
      <path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z" />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.Check';

setSymbol(Icon);

export default Icon;

import React, {createContext, ReactNode, useCallback, useMemo, useState} from 'react';
import type {ShowFlavoredFlashAlertAction, FlashAlertActions, FlashAlertProps} from './types';

export const FlashAlertContext = createContext<FlashAlertProps | undefined>(undefined);
export const FlashAlertActionsContext = createContext<FlashAlertActions | undefined>(undefined);

export const FlashAlertProvider = ({children}: {children: ReactNode}) => {
  const [flashAlertProps, setFlashAlertProps] = useState<FlashAlertProps>({});

  const hideAlert = useCallback(() => {
    setFlashAlertProps({});
  }, []);

  const showFlashAlert = useCallback((payload: FlashAlertProps) => {
    setFlashAlertProps({autoHide: true, displayDuration: 5000, ...payload});
  }, []);

  const showErrorFlashAlert: ShowFlavoredFlashAlertAction = useCallback(payload => {
    showFlashAlert({...payload, danger: true});
  }, []);

  const showInfoFlashAlert: ShowFlavoredFlashAlertAction = useCallback(payload => {
    showFlashAlert({...payload, informational: true});
  }, []);

  const actions = useMemo(
    () => ({
      hideAlert,
      showFlashAlert,
      showErrorFlashAlert,
      showInfoFlashAlert
    }),
    []
  );

  return (
    <FlashAlertContext.Provider value={flashAlertProps}>
      <FlashAlertActionsContext.Provider value={actions}>{children}</FlashAlertActionsContext.Provider>
    </FlashAlertContext.Provider>
  );
};

import {lazy, type ComponentType, type LazyExoticComponent} from 'react';
import {canSupportStorage} from '../../ui/behaviors/persistence/utils';

type DynamicComponentImportType<T> = () => Promise<{default: ComponentType<T>}>;
type LazyComponentType<T> = LazyExoticComponent<ComponentType<T>>;

const CHUNK_ERROR_REGEX = /loading .*chunk/i;

const isChunkLoadError = (error: unknown): error is Error => {
  if (typeof error === 'object') {
    const err = error as Error;

    return (
      ('name' in err && err.name === 'ChunkLoadError') || ('message' in err && CHUNK_ERROR_REGEX.test(err.message))
    );
  }

  if (typeof error === 'string') {
    return CHUNK_ERROR_REGEX.test(error);
  }

  return false;
};

const appendErrorMessage = (error: Error, message: string): void => {
  error.message = `${error.message} | ${message}`;
};

const handleChunkLoadError = (error: Error, hasAlreadyTriedToImport: boolean, sessionStorageKey: string): void => {
  if (!hasAlreadyTriedToImport) {
    sessionStorage.setItem(sessionStorageKey, 'true');
    window.location.reload();
  } else {
    appendErrorMessage(error, 'Session storage available');

    throw error;
  }
};

// Used instead of React.lazy in order to try and solve the following issue:
// https://www.codemzy.com/blog/fix-chunkloaderror-react
export const makeLazyLoadWithRetry =
  (appKey: string) =>
  <T>(componentImport: DynamicComponentImportType<T>, key: string): LazyComponentType<T> =>
    lazy((async () => {
      // In some cases session storage might not be available, e.g. while rendered inside an iframe
      if (canSupportStorage(sessionStorage)) {
        const sessionStorageKey = `${appKey}-${key}-lazy-import-retried`;

        const hasAlreadyTriedToImport = JSON.parse(sessionStorage.getItem(sessionStorageKey) || 'false') as boolean;

        try {
          const component = await componentImport();
          sessionStorage.setItem(sessionStorageKey, 'false');

          return component;
        } catch (error) {
          if (isChunkLoadError(error)) {
            handleChunkLoadError(error, hasAlreadyTriedToImport, sessionStorageKey);
          } else {
            throw error;
          }
        }
      } else {
        try {
          return await componentImport();
        } catch (error) {
          if (isChunkLoadError(error)) {
            appendErrorMessage(error, 'Session storage not available');
          }

          throw error;
        }
      }
    }) as DynamicComponentImportType<T>);

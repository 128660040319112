import React, {useCallback, useEffect, useRef, Suspense} from 'react';
import clsx from "clsx";
import {createPortal} from 'react-dom';
import {useFlashAlert, useFlashAlertActions} from '../hooks';
import styles from './alert.scss';

const FlashAlert = React.lazy(
  () => import(/* webpackChunkName: 'shared.ui.flash-alert' */ 'shared/ui/organisms/alert/flash')
);

type AlertProps = {
  className?: string;
  containerElement?: Element;
  currentPathname?: string;
};

const Alert = ({className, containerElement = document.body, currentPathname}: AlertProps) => {
  const {autoHide, children, dataUi, displayDuration, message, onClose, ...rest} = useFlashAlert();
  const {hideAlert} = useFlashAlertActions();

  const previousPathname = useRef(currentPathname);
  const hasContent = Boolean(message || children);

  const handleClose = useCallback(() => {
    hideAlert();

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [hideAlert, onClose]);

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;

    if (autoHide && hasContent) {
      timeout = setTimeout(handleClose, displayDuration);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoHide, displayDuration, handleClose, message]);

  useEffect(() => {
    if (currentPathname !== previousPathname.current) {
      previousPathname.current = currentPathname;
      handleClose();
    }
  }, [currentPathname, handleClose]);

  if (!message && !children) {
    return null;
  }

  return (
    (<Suspense fallback={null}>
      {createPortal(
        <FlashAlert
          {...rest}
          message={message}
          onClose={handleClose}
          className={clsx(clsx(clsx(styles.alert, styles.container), rest.className), className)}
          data-ui={dataUi}>
          {children}
        </FlashAlert>,
        containerElement
      )}
    </Suspense>)
  );
};

export default Alert;

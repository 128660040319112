export const DEBOUNCE_TIMEOUT_MS = 300;
export const IO_THROTTLE_MS = 100;
export const DEFAULT_TTL_MS = 60 * 60 * 1000;
export const META_STORE_KEY = 'evergreen-meta-peristence';
export const KEY_STORE_PREFIX = 'evergreen-persistence';
export const STORE_LOCATION = Object.freeze({
  LOCAL: 'local',
  SESSION: 'session',
  UNKNOWN: 'unknown'
});

import {useContext} from 'react';
import {FlashAlertContext, FlashAlertActionsContext} from './provider';

export const useFlashAlert = () => {
  const flashAlertProps = useContext(FlashAlertContext);
  if (typeof flashAlertProps === 'undefined') {
    throw new Error('useFlashAlert must be used within a FlashAlertProvider');
  }
  return flashAlertProps;
};

export const useFlashAlertActions = () => {
  const flashAlertActions = useContext(FlashAlertActionsContext);
  if (typeof flashAlertActions === 'undefined') {
    throw new Error('useFlashAlertActions must be used within a FlashAlertProvider');
  }
  return flashAlertActions;
};
